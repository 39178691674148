import * as React from 'react';

import MainTemplate from './src/templates/MainTemplate';

import { GatsbyBrowser } from 'gatsby';
import 'normalize.css';
import './src/styles/Buttons.css';
import './src/styles/DarkMode.css';
import './src/styles/Global.css';
import './src/styles/Template.css';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = (props) => (
	<MainTemplate {...props} />
);
